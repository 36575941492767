import { render, staticRenderFns } from "./LuckyDraw.vue?vue&type=template&id=3582a09d&scoped=true&"
import script from "./LuckyDraw.vue?vue&type=script&lang=js&"
export * from "./LuckyDraw.vue?vue&type=script&lang=js&"
import style0 from "./LuckyDraw.vue?vue&type=style&index=0&id=3582a09d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3582a09d",
  null
  
)

export default component.exports